import '../../css/general-sans.css'

import { createTheme, ThemeProvider } from '@mui/material'
import type {} from '@mui/x-date-pickers/themeAugmentation'

interface Props {
    children: React.ReactNode
}

const THEME = createTheme({
    typography: {
        fontFamily: `GeneralSans-Medium`,
        fontSize: 13,
    },
    palette: {
        primary: {
            main: '#0d9488',
        },
        error: {
            main: '#ef4444',
        },
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: 14,
                    color: 'black',
                    '&::before': {
                        border: 'none',
                        outline: 'none',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d1d5db',
                        borderRadius: '6px',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d1d5db',
                        borderRadius: '6px',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#14b8a6',
                        borderRadius: '6px',
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    '&:focus': {
                        outline: 'none',
                    },
                },
            },
        },
    },
})

export default function DesignParent({ children }: Props) {
    return <ThemeProvider theme={THEME}>{children}</ThemeProvider>
}
