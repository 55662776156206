import '../css/app.css'
import './bootstrap'

import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { createInertiaApp } from '@inertiajs/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import DesignParent from './Layouts/DesignParent'

const queryClient = new QueryClient()
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

const isProduction = process.env.NODE_ENV === 'production'

let cleanApp = () => {}

import.meta.glob(['../images/**'])

if (isProduction) {
    disableReactDevTools()
    cleanApp = () => document.getElementById('app')!.removeAttribute('data-page')
    document.addEventListener('inertia:finish', cleanApp)
}

createInertiaApp({
    progress: {
        color: '#4B5563',
    },
    title: (title) => `${title ? title + ' - ' : ''} ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el)

        root.render(
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesignParent>
                        <App {...props} />
                    </DesignParent>
                </LocalizationProvider>
            </QueryClientProvider>
        )
    },
}).then(cleanApp)
